import React from 'react';
import {Button, Menu, MenuItem, Chip, Theme, createStyles} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      minWidth: 300,
      display: 'flex',
      justifyContent: 'space-between'
    },
  }),
);

const DatabaseSelect = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button variant="outlined" color={'default'} aria-controls="database-select" aria-haspopup="true" onClick={handleClick}>
         Person <ArrowDropDown/>
      </Button>
      <Menu
        id="database-select"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}

      >
        <MenuItem onClick={handleClose} selected={true}>
          Person
        </MenuItem>
        <MenuItem onClick={handleClose} disabled={true}>
          <div className={classes.menuItem}>Risk <Chip color={'default'} label={'Em breve'} /></div>
        </MenuItem>
        <MenuItem onClick={handleClose} disabled={true}>
          <div className={classes.menuItem}>Limit <Chip color={'default'} label={'Em breve'} /></div>
        </MenuItem>
        <MenuItem onClick={handleClose} disabled={true}>
          <div className={classes.menuItem}>Dexter <Chip color={'default'} label={'Em breve'} /></div>
        </MenuItem>
        <MenuItem onClick={handleClose} disabled={true}>
          <div className={classes.menuItem}>Aang <Chip color={'default'} label={'Em breve'} /></div>
        </MenuItem>
      </Menu>
    </>
  );
}

export default DatabaseSelect
