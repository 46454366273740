import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {List, ListItem, ListItemIcon, ListItemText, ListSubheader} from '@material-ui/core';
import {PieChart} from '@material-ui/icons';
import BrandDefault from './BrandDefault';
import {Link} from 'react-router-dom';
import AdapterLink from './AdapterLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      minWidth: '250px',
      padding: '1rem',
      backgroundColor: '#e0e0e0'
    },
    brand: {
      padding: '0rem 1rem',
      marginBottom: '1.5rem',
      display: 'block'
    }
  }),
);

function SideBar() {
  const classes = useStyles();
  return (
    <nav className={classes.root}>
      <Link to="/" className={classes.brand} >
        <BrandDefault />
      </Link>
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button component={AdapterLink} to="/">
          <ListItemIcon>
            <PieChart />
          </ListItemIcon>
          <ListItemText primary="Segmentos" />
        </ListItem>
        <ListItem button  component={AdapterLink} to="/clusters">
          <ListItemIcon>
            <PieChart />
          </ListItemIcon>
          <ListItemText primary="Clusters" />
        </ListItem>
        <ListSubheader component="div" id="nested-list-subheader">
          Configuração
        </ListSubheader>
        <ListItem button disabled={true}>
          <ListItemText primary="Split IO Integration" />
        </ListItem>
      </List>
    </nav>
);
}

export default SideBar;
