import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Chip, TextField} from '@material-ui/core';
import FieldDataBaseSelect from './FieldDataBaseSelect';
import MatchSelect from './MatchSelect';
import ConditionSelect from './ConditionSelect';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    listStyle: 'none',
    padding: '0'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Condition() {
  const classes = useStyles();

  return (
    <ul className={classes.root}>
      <li>
        <Chip label={'WHEN'} color={'primary'} />
        <FieldDataBaseSelect />
        <MatchSelect />
        <TextField id="outlined-basic" label="Valor" variant="outlined" />
      </li>
      <li>
        <Chip label={'OR'} color={'secondary'} />
        <FieldDataBaseSelect />
        <MatchSelect />
        <TextField id="outlined-basic" label="Valor" variant="outlined" />
      </li>
      <li>
        <Chip label={'AND'} color={'secondary'} />
        <FieldDataBaseSelect />
        <MatchSelect />
        <TextField id="outlined-basic" label="Valor" variant="outlined" />
      </li>
      <li>
        <ConditionSelect />
      </li>
    </ul>
  );
}
