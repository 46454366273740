import React from 'react';
import {
  TableContainer,
  Table,
  Paper,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    marginTop: '1rem'
  },
});

function createData(id: string, name: string, updatedAt: Date) {
  return { id, name, updatedAt };
}

const rows = [
  createData('aads8d7a9d7a0as9d8', 'Segment Name', new Date()),
  createData('asd876as9d87a8s7d9as', 'Segment Name', new Date()),
  createData('as09d8a0sd8ad0a9s8d', 'Segment Name', new Date()),
  createData('asd90as8d0a9s8d', 'Segment Name', new Date()),
  createData('as75da6s5d7a6s5d8a', 'Segment Name', new Date()),
  createData('a98sd7a98sd7a987sd', 'Segment Name', new Date()),
  createData('asdo70asd79as7das87', 'Segment Name', new Date())
];

function DataList() {

  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Segmento</TableCell>
            <TableCell>Última atualização</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.updatedAt.toString()}</TableCell>
              <TableCell>
                <Link to={'/create'}>
                  Edit
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
);
}

export default DataList;
