import React from 'react';
import {Button, createStyles, Menu, MenuItem, Theme} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 1rem'
    },
  }),
);

const EnvironmentSelect = ()  =>{
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        color={'default'}
        aria-controls="environment-select"
        aria-haspopup="true" onClick={handleClick}>
        Production <ArrowDropDown/>
      </Button>
      <Menu
        id="environment-select"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          Production
        </MenuItem>
        <MenuItem onClick={handleClose}>
          Staging
        </MenuItem>
        <MenuItem onClick={handleClose}>
          Development
        </MenuItem>
      </Menu>
    </div>
);
}

export default EnvironmentSelect
