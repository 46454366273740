import React from 'react';
import SideBar from './SideBar';
import {Container} from './ContainerPage.style';

function ContainerPage({children}: any) {
  return (
    <Container>
      <SideBar/>
      <main>
        {children}
      </main>
    </Container>
  );
}

export default ContainerPage;
