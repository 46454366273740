import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  > main {
    flex-grow: 1;
  }
  
  section {
    padding: 1rem;
  }
`

