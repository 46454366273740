import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {AppBar as Bar} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    bar: {
      boxShadow: 'none',
      borderBottom: '1px solid rgba(0,0,0,.2)'
    }
  }),
);

export default function AppBar({children}: any) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Bar className={classes.bar} position="static" color={'inherit'}>
        <Toolbar>
          {children}
        </Toolbar>
      </Bar>
    </div>
  );
}
