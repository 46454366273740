import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Button, CardContent, CardHeader, TextField, Grid} from '@material-ui/core';
import Condition from './Condition';
import Card from '@material-ui/core/Card/Card';
import EnvironmentSelect from './EnvironmentSelect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    action: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '1rem'
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }),
);

function CreateForm() {

  const classes = useStyles();

  return (
    <form>
      <Card>
        <CardHeader
          title={'Configuração'}
          subheader={'Mussum Ipsum, cacilds vidis litro abertis. Todo mundo vê os porris que eu tomo, mas ninguém vê os tombis que eu levo!'} />
        <CardContent>
          <Grid container>
            <Grid item>
              <TextField id="outlined-basic" label="Nome" variant="outlined" fullWidth={true} />
            </Grid>
            <Grid item>
            </Grid>
            <Grid item>
              <TextField id="outlined-basic" label="Descrição" variant="outlined" fullWidth={true} />
            </Grid>
            <Grid item>
              <EnvironmentSelect/>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardHeader
          title={'Condições'}
          subheader={'Mussum Ipsum, cacilds vidis litro abertis. Todo mundo vê os porris que eu tomo, mas ninguém vê os tombis que eu levo!'} />
        <CardContent>
          <Condition/>
        </CardContent>
      </Card>

      <div className={classes.action}>
        <Button variant={'contained'} color={'default'}>Excluir</Button>
        <div>
          <Button variant={'contained'} color={'default'}>Cancelar</Button>
          <Button variant={'contained'} color={'primary'}>Salvar</Button>
        </div>
      </div>
    </form>
  );
}

export default CreateForm;
