import React from 'react';
import {Button, Menu, MenuItem} from '@material-ui/core';
import {Add} from '@material-ui/icons';

const ConditionSelect = ()  =>{
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        color={'inherit'}
        aria-controls="environment-select"
        aria-haspopup="true" onClick={handleClick}>
        Adicionar <Add/>
      </Button>
      <Menu
        id="environment-select"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          Or
        </MenuItem>
        <MenuItem onClick={handleClose}>
          And
        </MenuItem>
      </Menu>
    </div>
);
}

export default ConditionSelect
