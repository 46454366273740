import React from 'react';
import {Typography} from '@material-ui/core'
import ContainerPage from '../../components/ContainerPage';
import CreateForm from '../../components/CreateForm';

function Create() {
  return (
    <ContainerPage>
      <section>
        <Typography variant="h3" component="h1">
          Criar Segmento
        </Typography>
        <CreateForm/>
      </section>
    </ContainerPage>
  );
}

export default Create;
