import React from 'react';
import {Typography, Button, Theme, createStyles} from '@material-ui/core'
import ContainerPage from '../../components/ContainerPage';
import DatabaseSelect from '../../components/DatabaseSelect';
import EnvironmentSelect from '../../components/EnvironmentSelect';
import AppBar from '../../components/AppBar';
import DataList from '../../components/DataList';
import {makeStyles} from '@material-ui/core/styles';
import AdapterLink from '../../components/AdapterLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1rem'
    },
  }),
);


function List() {
  const classes = useStyles();

  return (
    <ContainerPage>
      <AppBar>
        <DatabaseSelect/>
        <EnvironmentSelect/>
      </AppBar>

      <section>
        <div className={classes.title}>
          <Typography variant="h3" component="h1">
            Segmentos
          </Typography>

          <Button variant={'contained'} color={'primary'} component={AdapterLink} to={'/create'}>Criar segmento</Button>
        </div>
        <DataList />
      </section>
    </ContainerPage>
);
}

export default List;
