import React, { Component } from 'react'

import { BrowserRouter, Switch, Route } from 'react-router-dom'
import List from './pages/Clusters/List';
import ListSegment from './pages/Segments/List';
import Create from './pages/Segments/Create';


export class App extends Component<any> {
  componentDidCatch() {}

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            component={ListSegment}
            exact
            path={'/'}
          />
          <Route
            component={List}
            exact
            path={'/clusters'}
          />
          <Route
            component={Create}
            path={'/create'}
          />
          <Route
            component={Create}
            path={'/create/:id'}
          />
        </Switch>
      </BrowserRouter>
    )
  }
}
